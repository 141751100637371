<template>
	<div role="group" class="select_items_filter_wrapper">
		<label v-if="withLabel" class="d-block" v-html="'Type'" />
		<b-row>
			<b-col :md="`${canDoAdd ? 8 : 12}`" :lg="`${canDoAdd ? 8 : 12}`">
				<v-select
					v-model="taskType"
					:placeholder="placeholder"
					class="mb-2"
					:options="taskTypes"
					@input="taskTypeClear"
					@option:selected="setIsCanModify"
					:style="`${!isValid ? 'border: 1px solid #B11548; border-radius: 4px;' : ''}`"
					:disabled="disabled"
					><!-- @input="setIsCanModify" -->
					<template #selected-option="option">
						<span v-if="option.picture">
							<b-img :src="showPicture(option.picture)" :alt="option.picture" width="26" height="26" class="m1" />
							<span style="margin-left: 10px">{{ option.name | capitalize }}</span>
						</span>
						<span v-else>
							<b-img v-bind="mainPropsThumb" rounded="circle" alt="Circle image" />
							<span style="margin-left: 10px">{{ option.name | capitalize }}</span>
						</span>
					</template>
					<template #option="option">
						<span v-if="option.picture">
							<b-img :src="showPicture(option.picture)" rounded="circle" :alt="option.picture" width="30" height="30" class="m1" />
							<span style="margin-left: 10px">{{ option.name | capitalize }}</span>
						</span>
						<span v-else>
							<b-img v-bind="mainProps" rounded="circle" alt="Circle image" />
							<span style="margin-left: 10px">{{ option.name | capitalize }}</span>
						</span>
					</template>
					<template #no-options="{ search, searching }">
						<template v-if="searching">
							{{ FormMSG(1, 'No results for') }} <em>{{ search }}</em
							>. <span>{{ FormMSG(2, 'You can add it') }}.</span>
						</template>
						<em v-else style="opacity: 0.5"> {{ FormMSG(3, 'Start typing to search for a task type') }}. </em>
					</template>
				</v-select>
			</b-col>
			<b-col md="4" lg="4" v-if="canDoAdd">
				<div class="supplier_actions_btns">
					<b-button block variant="outline-primary" @click="addATaskType" v-html="addLabel" class="py-2" />
				</div>
			</b-col>
		</b-row>
		<task-type-modal
			v-model="openModal"
			:is-editable="is_editable"
			:data-to-edit="dataToEdit"
			@task-type:create="taskTypeCreate"
			@task-type:update="taskTypeUpdate"
			@task-type:cancel="openModal = false"
			@task-type:delete="taskTypeDelete"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import TaskTypeModal from './TaskTypeModal';
import { addUpdateTaskType, getTaskTypes, deleteTaskType } from '@/cruds/tasktypes.crud';
import { omit, isNil } from '@/shared/utils';
// import { store } from "@/store";

export default {
	name: 'TaskTypeSelector',

	components: {
		TaskTypeModal
	},

	mixins: [languageMessages],

	props: {
		placeholder: {
			type: String,
			default: 'Choose type ...'
		},
		addLabel: {
			type: String,
			default: 'Add'
		},
		withLabel: {
			type: Boolean,
			default: false
		},
		isValid: {
			type: Boolean,
			required: false,
			default: true
		},
		taskTypeId: {
			type: Number,
			required: false,
			default: 0
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		canDoAdd: {
			type: Boolean,
			required: false,
			default: true
		}
	},

	data() {
		return {
			openModal: false,
			dataToEdit: {},
			is_editable: false,
			taskTypes: [],
			taskType: null,
			isCanModify: false,
			mainProps: {
				blank: true,
				blankColor: '#777',
				width: 30,
				height: 30,
				class: 'm1'
			},
			mainPropsThumb: {
				blank: true,
				blankColor: '#777',
				width: 15,
				height: 15,
				class: 'm1'
			},
			oldTaskTypeId: 0
		};
	},
	mounted() {},
	methods: {
		showPicture(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		setIsCanModify(payload) {
			if (payload === null) {
				this.isCanModify = false;
				this.emitEvent(null, !(parseInt(this.oldTaskTypeId, 10) === parseInt(payload.id, 10)));
			} else {
				this.isCanModify = true;
				this.emitEvent(payload, !(parseInt(this.oldTaskTypeId, 10) === parseInt(payload.id, 10)));
			}
		},
		taskTypeClear(payload) {
			this.$emit('task-type:clearSelection', true);
		},
		async addATaskType() {
			// const response = await addUpdateTaskType(0, { name: 'New Task Type' })
			// this.dataToEdit = response
			// this.is_editable = false
			// this.openModal = true
			this.$router.push({
				path: '/task-types'
			});
		},
		editATaskType() {
			this.dataToEdit = this.taskType;
			this.is_editable = true;
			this.openModal = true;
		},
		async allTaskType() {
			let response = await getTaskTypes();
			response.map((r) => {
				r.label = r.name;
			});
			this.taskTypes = response;
			if (this.taskTypeId !== 0) {
				const taskTypeSelected = this.taskTypes.filter((taskType) => parseInt(taskType.id, 10) === this.taskTypeId);
				if (taskTypeSelected.length > 0) {
					this.taskType = taskTypeSelected[0];
					this.dataToEdit = taskTypeSelected[0];
					this.isCanModify = true;
				}
				this.oldTaskTypeId = this.taskType.id;
			}
		},
		async taskTypeCreate(payload) {
			const response = await addUpdateTaskType(payload.id, payload);
			const val = {
				label: response.name,
				...response
			};
			this.taskType = val;
			this.taskTypes.push(val);
			this.isCanModify = true;
			this.emitEvent(val);
		},
		async taskTypeUpdate(payload) {
			const tempTaskTypes = this.taskTypes;
			this.taskTypes = [];
			const response = await addUpdateTaskType(payload.id, payload);
			const val = {
				label: response.name,
				...response
			};
			this.taskType = val;
			const findIndex = tempTaskTypes.findIndex((t) => t.id === val.id);
			tempTaskTypes[findIndex] = val;
			this.taskTypes = tempTaskTypes;
			this.emitEvent(val);
		},
		async taskTypeDelete(payload) {
			await deleteTaskType(payload.id);
			this.openModal = false;
			this.taskType = {};
			this.taskTypes = this.taskTypes.filter((t) => t.id !== payload.id);
			this.is_editable = false;
			this.isCanModify = false;
			this.emitEvent(null);
		},
		emitEvent(payload, arg = null) {
			if (!isNil(payload)) {
				const _p = {
					...omit(['__typename', 'label'], payload)
				};
				this.$emit('task-type:selected', _p, arg);
			} else {
				this.$emit('task-type:selected', payload, arg);
			}
		}
	},
	watch: {
		taskTypeId: {
			handler: async function (n) {
				await this.allTaskType();
				if (!isNil(n) && n > 0) {
					const taskTypeSelected = this.taskTypes.filter((taskType) => parseInt(taskType.id, 10) === n);
					if (taskTypeSelected.length > 0) {
						this.taskType = taskTypeSelected[0];
						this.dataToEdit = taskTypeSelected[0];
						this.isCanModify = true;
					}
					this.oldTaskTypeId = this.taskType.id;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>

<style scoped></style>
