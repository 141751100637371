var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select_items_filter_wrapper", attrs: { role: "group" } },
    [
      _vm.withLabel
        ? _c("label", {
            staticClass: "d-block",
            domProps: { innerHTML: _vm._s("Type") },
          })
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              attrs: {
                md: `${_vm.canDoAdd ? 8 : 12}`,
                lg: `${_vm.canDoAdd ? 8 : 12}`,
              },
            },
            [
              _c("v-select", {
                staticClass: "mb-2",
                style: `${
                  !_vm.isValid
                    ? "border: 1px solid #B11548; border-radius: 4px;"
                    : ""
                }`,
                attrs: {
                  placeholder: _vm.placeholder,
                  options: _vm.taskTypes,
                  disabled: _vm.disabled,
                },
                on: {
                  input: _vm.taskTypeClear,
                  "option:selected": _vm.setIsCanModify,
                },
                scopedSlots: _vm._u([
                  {
                    key: "selected-option",
                    fn: function (option) {
                      return [
                        option.picture
                          ? _c(
                              "span",
                              [
                                _c("b-img", {
                                  staticClass: "m1",
                                  attrs: {
                                    src: _vm.showPicture(option.picture),
                                    alt: option.picture,
                                    width: "26",
                                    height: "26",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm._f("capitalize")(option.name))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              [
                                _c(
                                  "b-img",
                                  _vm._b(
                                    {
                                      attrs: {
                                        rounded: "circle",
                                        alt: "Circle image",
                                      },
                                    },
                                    "b-img",
                                    _vm.mainPropsThumb,
                                    false
                                  )
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm._f("capitalize")(option.name))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                  {
                    key: "option",
                    fn: function (option) {
                      return [
                        option.picture
                          ? _c(
                              "span",
                              [
                                _c("b-img", {
                                  staticClass: "m1",
                                  attrs: {
                                    src: _vm.showPicture(option.picture),
                                    rounded: "circle",
                                    alt: option.picture,
                                    width: "30",
                                    height: "30",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm._f("capitalize")(option.name))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              [
                                _c(
                                  "b-img",
                                  _vm._b(
                                    {
                                      attrs: {
                                        rounded: "circle",
                                        alt: "Circle image",
                                      },
                                    },
                                    "b-img",
                                    _vm.mainProps,
                                    false
                                  )
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm._f("capitalize")(option.name))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                  {
                    key: "no-options",
                    fn: function ({ search, searching }) {
                      return [
                        searching
                          ? [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(1, "No results for")) +
                                  " "
                              ),
                              _c("em", [_vm._v(_vm._s(search))]),
                              _vm._v(". "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(2, "You can add it")) + "."
                                ),
                              ]),
                            ]
                          : _c("em", { staticStyle: { opacity: "0.5" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.FormMSG(
                                      3,
                                      "Start typing to search for a task type"
                                    )
                                  ) +
                                  ". "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.taskType,
                  callback: function ($$v) {
                    _vm.taskType = $$v
                  },
                  expression: "taskType",
                },
              }),
            ],
            1
          ),
          _vm.canDoAdd
            ? _c("b-col", { attrs: { md: "4", lg: "4" } }, [
                _c(
                  "div",
                  { staticClass: "supplier_actions_btns" },
                  [
                    _c("b-button", {
                      staticClass: "py-2",
                      attrs: { block: "", variant: "outline-primary" },
                      domProps: { innerHTML: _vm._s(_vm.addLabel) },
                      on: { click: _vm.addATaskType },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("task-type-modal", {
        attrs: {
          "is-editable": _vm.is_editable,
          "data-to-edit": _vm.dataToEdit,
        },
        on: {
          "task-type:create": _vm.taskTypeCreate,
          "task-type:update": _vm.taskTypeUpdate,
          "task-type:cancel": function ($event) {
            _vm.openModal = false
          },
          "task-type:delete": _vm.taskTypeDelete,
        },
        model: {
          value: _vm.openModal,
          callback: function ($$v) {
            _vm.openModal = $$v
          },
          expression: "openModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }