var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.titleModal,
        size: "md",
        "ok-variant": "success",
        "ok-title": _vm.okTitle,
        "cancel-title": _vm.FormMSG(2, "Cancel"),
      },
      on: { ok: _vm.onSubmit, cancel: _vm.onCancel, hidden: _vm.onCancel },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-card",
        [
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(5, "Name") } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              required: "",
                              placeholder: _vm.FormMSG(5, "Name"),
                            },
                            model: {
                              value: _vm.taskType.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskType, "name", $$v)
                              },
                              expression: "taskType.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        [
                          !_vm.taskType.isSystem
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "btn-block mb-3",
                                  attrs: {
                                    variant: "outline-secondary",
                                    size: "md",
                                  },
                                  on: { click: _vm.startCapture },
                                },
                                [
                                  _c("span", [
                                    _c("i", { staticClass: "icon-camera" }),
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(43, "Change your picture")
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.has(_vm.taskType.picture) &&
                          _vm.taskType.picture !== ""
                            ? _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-block d-flex justify-content-center align-items-center",
                                  attrs: { variant: "outline-secondary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showPicture([
                                        _vm.taskType.picture,
                                      ])
                                    },
                                  },
                                },
                                [
                                  _c(
                                    _vm.getLucideIcon(_vm.ICONS.PICTURE.name),
                                    {
                                      tag: "component",
                                      staticClass: "mr-2",
                                      attrs: { size: 20 },
                                    }
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "1px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            52,
                                            "View current picture"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(7, "Duration") } },
                        [
                          _c("b-form-timepicker", {
                            attrs: {
                              type: "text",
                              required: "",
                              placeholder: _vm.FormMSG(7, "Duration"),
                            },
                            model: {
                              value: _vm.taskType.duration,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskType, "duration", $$v)
                              },
                              expression: "taskType.duration",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("NewCapture", {
                key: _vm.refreshTokenCaptureItem,
                attrs: {
                  "parent-type": "task_type",
                  "parent-id": _vm.taskType.id,
                  "auto-crop-capture": "",
                  "cam-mode-first": false,
                  "only-one-crop-mode": "",
                },
                on: { change: _vm.handleImgsTaked },
                model: {
                  value: _vm.capturePict,
                  callback: function ($$v) {
                    _vm.capturePict = $$v
                  },
                  expression: "capturePict",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }