<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isOpen"
		:title="titleModal"
		size="md"
		ok-variant="success"
		:ok-title="okTitle"
		:cancel-title="FormMSG(2, 'Cancel')"
		@ok="onSubmit"
		@cancel="onCancel"
		@hidden="onCancel"
	>
		<b-card>
			<b-card-body>
				<b-row>
					<b-col>
						<b-form-group :label="FormMSG(5, 'Name')">
							<b-form-input v-model="taskType.name" type="text" required :placeholder="FormMSG(5, 'Name')" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group>
							<b-button variant="outline-secondary" size="md" @click="startCapture" class="btn-block mb-3" v-if="!taskType.isSystem">
								<span>
									<i class="icon-camera" />
									{{ FormMSG(43, 'Change your picture') }}
								</span>
							</b-button>
							<b-button
								variant="outline-secondary"
								class="btn-block d-flex justify-content-center align-items-center"
								v-if="has(taskType.picture) && taskType.picture !== ''"
								@click="showPicture([taskType.picture])"
							>
								<component :is="getLucideIcon(ICONS.PICTURE.name)" :size="20" class="mr-2" />
								<div style="margin-top: 1px">{{ FormMSG(52, 'View current picture') }}</div>
							</b-button>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group :label="FormMSG(7, 'Duration')">
							<b-form-timepicker v-model="taskType.duration" type="text" required :placeholder="FormMSG(7, 'Duration')" />
						</b-form-group>
					</b-col>
				</b-row>
				<NewCapture
					:key="refreshTokenCaptureItem"
					v-model="capturePict"
					parent-type="task_type"
					:parent-id="taskType.id"
					auto-crop-capture
					:cam-mode-first="false"
					only-one-crop-mode
					@change="handleImgsTaked"
				/>
			</b-card-body>
		</b-card>
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import meterHelperMixin from '../LocationService/meterHelperMixin';
import { store } from '@/store';
import NewCapture from '@/components/CaptureModule/Main';
import { addUpdateTaskType } from '@/cruds/tasktypes.crud';
import { getFileExtension } from '@/shared/helpers';
import { isNil } from '@/shared/utils';
import globalMixin from '@/mixins/global.mixin';

const fieldTaskType = { name: '', picture: '', duration: '00:00:00' };

export default {
	name: 'TaskTypeModal',
	components: { NewCapture },
	mixins: [languageMessages, meterHelperMixin, globalMixin],
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		dataToEdit: {
			type: Object,
			required: false,
			default: () => {}
		}
		/*isEditable: {
      type: Boolean,
      required: true,
    },*/
	},
	data() {
		return {
			capturePict: false,
			refreshTokenCaptureItem: 0
		};
	},

	computed: {
		/**
		 * @return {Boolean}
		 */
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return val;
			}
		},

		titleModal() {
			// if (this.isEditable) {
			return this.FormMSG(3, 'Edit task type');
			// } else {
			//   return this.FormMSG(1, 'Add task type')
			// }
		},
		okTitle() {
			// if (!this.isEditable) {
			//   return this.FormMSG(1, 'Add task type')
			// } else {
			return this.FormMSG(4, 'Save');
			// }
		},
		taskType() {
			const duration = this.splitDuration(this.dataToEdit.duration);
			return { ...this.dataToEdit, duration };
		},
		rendConfirmModalTxt() {
			const _t = this.FormMSG;
			return {
				title: _t(56561, 'Please Confirm'),
				okTitle: _t(56562, 'YES'),
				cancelTitle: _t(56563, 'NO')
			};
		}
	},

	methods: {
		async handleImgsTaked(imgs) {
			const retStr = imgs[0];
			this.taskType.picture = retStr;
			store.state.imageCropped = '';
			await addUpdateTaskType(this.taskType.id, {
				picture: this.taskType.picture
			});
			this.refreshTokenCaptureItem = this.refreshTokenCaptureItem + 1;
		},
		startCapture: function () {
			// initialise global store variable and open dialog to capture and crop
			//console.log("instartcapture");
			store.state.capturedImage = '';
			store.state.imageCropped = '';
			this.capturePict = true;
		},
		onSubmit() {
			this.taskType.duration = this.prepareDuration(this.taskType.duration);
			// if (this.isEditable) {
			// IF UPDATE
			this.$emit('task-type:update', this.taskType);
			// } else {
			//   // IF CREATE
			//   this.$emit('task-type:create', this.taskType)
			// }
		},
		onCancel() {
			this.$emit('task-type:cancel', false);
		},

		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true
				}
			});
		},

		prepareDuration(duration) {
			return `0001-01-01T${duration}Z`;
		},
		splitDuration(duration) {
			const date = new Date(duration);
			return `${this.addZero(date.getUTCHours())}:${this.addZero(date.getUTCMinutes())}:00`;
		},
		addZero(time) {
			return time > 10 ? time : '0' + time;
		},
		handleDeleteTaskType() {
			if (!this.isEditable) return;

			const txt = this.FormMSG(20, 'Please confirm that you want to delete this customer');
			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((v) => {
					if (!v) return;
					this.$emit('task-type:delete', this.taskType);
				})
				.catch((err) => {
					this.createToastForMobile(this.FormMSG(55, 'Error'), err.message, '', 'danger');
				});
		}
	}
};
</script>
